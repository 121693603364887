<script setup>
import { ref, reactive, onMounted } from "vue";

import openAiLogo from "@/assets/img/logo/openai.svg";
import claudeLogo from "@/assets/img/logo/claude.svg";
import mistralLogo from "@/assets/img/logo/mistral.svg";
import metaLogo from "@/assets/img/logo/meta.svg";

import { API } from "@/services/api";
import useClientConfig from "@/stores/client-config";
import useQuotaStore from "@/stores/quota";

import ErrorMessage from "@/components/common/ErrorMessage.vue";
import LoadingBlock from "@/components/common/LoadingBlock.vue";
import LoadingText from "@/components/common/LoadingText.vue";
import LockedBadge from "@/components/common/LockedBadge.vue";
import Select from "@/components/common/Select.vue";
import Switch from "@/components/common/Switch.vue";
import Tooltip from "@/components/common/Tooltip.vue";
import MarkdownResult from "@/components/common/MarkdownResult.vue";

import sendApiRequest from "@/services/api/_ai_request";
import analytics from "@/services/api/analytics";

import translateIcon from "@/assets/img/icons/translate.svg";

const clientConfigStore = useClientConfig();

// Models

const supportedModels = reactive({
    "gpt-4": {
        label: "GPT-4",
        icon: openAiLogo,
    },
});

const supportedLangs = reactive({
    "": {
        label: "Auto",
        icon: translateIcon,
    },
});
const supportedLangEmojis = {
    "de": "🇩🇪", "en": "🇬🇧",
    "es": "🇪🇸", "fr": "🇫🇷", "it": "🇮🇹",
    "ja": "🇯🇵", "pt": "🇵🇹", "zh": "🇨🇳",
};

async function getClientConfig() {
    const { clientConfig } = clientConfigStore;

    if (clientConfig.supported_models) {
        Object.entries(clientConfig.supported_models).forEach(([key, value]) => {
            let icon = null;

            // God will punish us for this
            if (key.startsWith("gpt")) icon = openAiLogo;
            if (key.startsWith("claude")) icon = claudeLogo;
            if (key.startsWith("mistral")) icon = mistralLogo;
            if (key.startsWith("llama")) icon = metaLogo;

            supportedModels[key] = {
                label: value,
                icon,
            };
        });
    }

    if (clientConfig.supported_langs) {
        Object.entries(clientConfig.supported_langs).forEach(([key, value]) => {
            supportedLangs[key] = {
                label: value,
                emoji: supportedLangEmojis[key] ?? "🏳️",
            };
        });
    }
}

onMounted(async () => {
    getClientConfig();
});

// Results

const quotaStore = useQuotaStore();

const ask = reactive({
    loading: false,
    loaded: true,
    hasLoadedBefore: false,
    question: null,
    previousQuestion: null,
    answer: "",
    sources: [],
    historyId: "",
    errorText: "",
    deep: false,
    model: "gpt-4",
    lang: "",
    disconnectPort: null,
});
const lastQueryTime = ref(null);

function clear() {
    ask.answer = "";
    ask.sources = [];
    ask.historyId = "";
    ask.errorText = "";
}

function handleTextareaKeyup(e) {
    const keyCode = e.which || e.keyCode;

    if (keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        getAnswer();
    }
}

async function getAnswer(force = false) {
    if (quotaStore.quotaInfo.exhausted) return;

    try {
        let currentQuestion = ask.question;
        if (!currentQuestion) {
            currentQuestion = ask.previousQuestion;
        } else {
            ask.previousQuestion = currentQuestion;
        }

        const params = {
            question: currentQuestion,
            lang: ask.lang,
        };

        if (quotaStore.quotaInfo.plan !== "free" && ask.deep) {
            params.deep = ask.deep;
            params.model = ask.model;
        }

        const start = Date.now();
        lastQueryTime.value = start;

        if (!ask.question && !ask.previousQuestion) {
            return;
        }

        const res = await sendApiRequest(API.articles.ask, params, force);

        ask.loading = true;
        ask.loaded = false;
        clear();

        if (res instanceof WebSocket) {
            ask.disconnectPort = () => res.close();

            await new Promise((resolve, reject) => {
                res.addEventListener("message", (message) => {
                    const messageData = JSON.parse(message.data);
                    if (start !== lastQueryTime.value) return;
                    if (messageData.error) reject(messageData);
                    handleMessage(messageData);
                });

                res.addEventListener("close", () => {
                    if (start !== lastQueryTime.value) return;
                    handleClose(start, params);

                    resolve();
                });
            });
        } else {
            res.forEach((message) => {
                handleMessage(message);
            });
            handleClose(start, params);
        }

        ask.loaded = true;
    } catch (err) {
        handleError(err);
    }
}

function handleMessage(messageData) {
    ask.loading = false;
    ask.hasLoadedBefore = true;
    if (messageData.token) ask.answer += messageData.token;
    if (messageData.sources) ask.sources = messageData.sources;
    if (messageData.history_id) ask.historyId = messageData.history_id;
}

function handleClose(start, params) {
    ask.loading = false;
    ask.loaded = true;

    const end = Date.now();
    if (!ask.errorText) {
        analytics.push({
            type: "ask_result",
            ask_type: "platform",
            ask_context: ask.useArticleAsContext ? "article" : "internet",
            time: (end - start) / 1000,
            deep: params.deep,
            search_type: params.search_type,
            model: params.model,
        });
    }
}

function handleError(err) {
    switch (err.error) {
    case "unprocessable_entity_error":
        ask.errorText = "This article's language is not supported yet.";
        break;
    case "rate_limited":
        ask.errorText = "You're going too fast! Please try again in a moment.";
        break;
    case "request_timeout":
        ask.errorText = "Your request timed out. Please try again later.";
        break;
    case "provider_error":
        ask.errorText = "We encountered an issue with one of our external service providers. Please try again later.";
        break;
    case "quota_exhausted":
        ask.errorText = "You have run out of queries for the day. Please try again later.";
        break;
    default:
        ask.errorText = "An error happened while getting the answer to your question";
        break;
    }
}

// Helper

function resizeOnInput(e) {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
}

function linkIcon(link) {
    try {
        const url = new URL(link);
        return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url.protocol}//${url.host}`;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

function getLinkName(link) {
    const url = new URL(link);
    return url.hostname.split(".").slice(-2, -1)[0];
}

function copyResult() {
    navigator.clipboard.writeText(ask.answer);
}
</script>

<template>
    <div class="wiseone-search-container">
        <div class="chat">
            <LoadingBlock :cap="20" v-if="ask.loading">
                <LoadingText :lines="2" :width="22" class="loading"/>
                <LoadingText :lines="4" :width="88" class="loading"/>
                <LoadingText :lines="3" :width="44" class="loading"/>
            </LoadingBlock>
            <ErrorMessage
                v-else-if="ask.errorText"
                :message="ask.errorText"
                class="error-container"
                @retry="getAnswer"
            />
            <div class="message" v-else-if="ask.answer">
                <MarkdownResult
                    :markdown="ask.answer"
                    :loaded="ask.loaded"
                ></MarkdownResult>
                <span
                    class="sources-list"
                    v-if="ask.sources && ask.sources.length"
                >
                    <a
                        class="source"
                        v-for="source, index in ask.sources"
                        :key="source"
                        :href="source"
                        @click="onLinkOpen()"
                        target="_blank"
                    >
                        <span class="source-number">{{ index + 1 }}</span>
                        <img v-if="linkIcon(source)" :src="linkIcon(source)" class="source-icon"/>
                        <span class="source-name">{{ getLinkName(source) }}</span>
                    </a>
                </span>
                <div class="message-bar" v-if="ask.answer && ask.loaded">
                    <Tooltip text="Copy">
                        <img
                            class="copy"
                            src="@/assets/img/icons/copy.svg"
                            @click="copyResult"
                        />
                    </Tooltip>
                    <Tooltip text="Retry">
                        <img
                            class="retry"
                            src="@/assets/img/icons/refresh.svg"
                            @click="getAnswer(true)"
                        />
                    </Tooltip>
                </div>
            </div>
            <div v-else class="enter-input">
                <span>
                    Ask your question in the field below.
                </span>
            </div>
        </div>
        <div class="parameters-container">
            <div
                v-if="quotaStore.quotaInfo && quotaStore.quotaInfo.plan !== 'free'"
                @click="ask.deep = !ask.deep"
                class="param-switch"
            >
                Deep Search
                <Switch
                    class="switch"
                    v-model:checked="ask.deep"
                    :disabled="true"
                ></Switch>
            </div>
            <div v-else class="param-switch disabled">
                <div class="locked">
                    <LockedBadge></LockedBadge>
                    Upgrade for Deep Ask mode
                    <div class="blur">
                        <Switch
                            :checked="false"
                        ></Switch>
                    </div>
                </div>
            </div>
            <Select
                v-if="ask.deep"
                class="param-select model"
                v-model="ask.model"
                :options="supportedModels"
                :displayed="supportedModels[ask.model]?.label"
                :displayedIcon="supportedModels[ask.model]?.icon"
                alignText="left"
                alt="model select"
                menuType="popup"
                menuName="Select your model"
            ></Select>
            <Select
                class="param-select lang"
                v-model="ask.lang"
                :options="supportedLangs"
                :displayed="supportedLangs[ask.lang]?.label"
                :displayedIcon="supportedLangs[ask.lang]?.icon"
                :displayedEmoji="supportedLangEmojis[ask.lang]"
                alignText="left"
                alt="lang select"
                menuType="popup"
                menuName="Select your language"
            ></Select>
            <div class="query-counter" v-if="quotaStore.quotaInfo && quotaStore.quotaInfo.plan === 'free'">
                {{ Math.floor(quotaStore.quotaInfo.limit - quotaStore.quotaInfo.used) }}
                {{ (quotaStore.quotaInfo.limit - quotaStore.quotaInfo.used) !== 1 ? "queries" : "query" }} left
            </div>
        </div>
        <div class="input-container">
            <div class="input">
                <textarea
                    class="search-input"
                    v-model="ask.question"
                    wrap="hard"
                    @input="resizeOnInput"
                    @keydown="handleTextareaKeyup"
                    rows="1"
                    placeholder="Ask a question"
                ></textarea>
                <img
                    class="search-button"
                    :class="{ disabled: quotaStore.quotaInfo?.exhausted }"
                    src="@/assets/img/icons/search.svg"
                    @click="getAnswer"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>
.wiseone-search-container {
    max-height: calc(100% - 64px);
}

.error-container {
    flex: 1;
}

:deep(.error) {
    color: unset;
}

.locked {
    display: flex;
    align-items: center;
}

.blur {
    margin-left: 12px;
    filter: blur(2px);
    pointer-events: none;
}

/* Chat */

.chat {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    font-size: 15px;
    line-height: 1.2em;
    overflow-y: scroll;
}

.chat::-webkit-scrollbar {
    width: 12px;
}

.chat::-webkit-scrollbar-thumb {
    cursor: pointer;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #3f48428c;
}

.message {
    display: flex;
    flex-direction: column;
    font-family: Quicksand;
    padding: 0 20px;
    border-radius: 18px;
}

.enter-input {
    color: #A1A1A1;
    font-family: Quicksand;
    display: flex;
    width: 100%;
    padding: 45px;
    flex: 1;
    justify-content: center;
    align-items: center;
}

/* Message bottom bar */

.message-bar {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
}

.message-bar img {
    padding: 0 4px;
    height: 20px;
    width: 28px;
    cursor: pointer;
    filter: invert(75%) sepia(4%) saturate(1410%) hue-rotate(212deg) brightness(100%) contrast(85%);
}

.message-bar img:hover {
    filter: invert(11%) sepia(79%) saturate(7369%) hue-rotate(257deg) brightness(76%) contrast(129%);
}

/* Parameters */

.parameters-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    flex-direction: row;
    margin: 16px 0;
    gap: 8px 18px;
}

.param-switch {
    gap: 16px;
    display: flex;
    flex-direction: row;
    border-radius: 9999px;
    border: 1px solid #9E97BC;
    padding: 8px 10px 8px 14px;
    cursor: pointer;
}

.param-switch.disabled {
    cursor: default;
}

.param-switch:not(.disabled):hover {
    border: 1px solid #4d00d4;
}

.param-select {
    border-radius: 9999px;
}

.param-select :deep(.select) {
    padding: 8px 10px;
}

.lang-icon {
    height: 16px;
    margin-right: 8px;
}

/* Input */

.input {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: end;
}

.input > img {
    filter: invert(13%) sepia(98%) saturate(5221%) hue-rotate(257deg) brightness(73%) contrast(142%);
}

.input-container {
    width: 100%;
    background-color: white;
    padding: 18px;
    border-radius: 12px;
    position: sticky;
}

.search-input {
    width: 100%;
    border: none;
    font-family: Quicksand;
    font-size: 15px;
    background-color: transparent;
    font-weight: 500;
    resize: none;
    max-height: 99px;
    overflow: auto;
    overscroll-behavior: contain;
    height: 23px;
}

.search-input::-webkit-scrollbar {
    width: 12px;
}

.search-input::-webkit-scrollbar-thumb {
    cursor: pointer;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #3f48428c;
}

:is(input, textarea):focus-visible {
    border: 0;
    outline: none;
}

.search-button {
    cursor: pointer;
}

.search-button.disabled {
    cursor: default;
    filter: invert(50%) sepia(6%) saturate(169%) hue-rotate(214deg) brightness(95%) contrast(95%);
}

/* Sources list */

.sources-list {
    padding-top: 10px;
}

.source {
    background-color: #F3F0FF;
    color: rgba(0, 0, 0, 0.9);
    text-decoration: none;
    border-radius: 9999px;
    padding: 3px 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 4px;
    transition: linear 0.2s;
    user-select: none;
}

.source:hover {
    background-color: #ebe8f7;
}

.source:not(:last-child) {
    margin-right: 5px;
}

.source > * {
    display: inline;
    vertical-align: middle;
}

.source > img {
    border-radius: 50%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
}

.source-number {
   font-weight: 700;
   color: #9D96BA;
   margin-right: 8px;
}

.source-icon {
    margin-right: 4px;
}

.source-name {
    font-weight: 500;
    color: #4E5A52;
}

/* Query counter */

.query-counter {
    font-family: Quicksand;
    margin-top: 8px;
    margin-left: auto;
}

</style>
